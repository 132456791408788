define(['app'], function (app) {
  class FacetHelper {

    constructor() {
      this.init();
    }

    init() {
      this.setup();
    }

    setup() {
      window.addEventListener('click', function (event) {
        app.publish('horizontalFacets/click', event);
      });

      window.addEventListener('keydown', function (event) {
        app.publish('horizontalFacets/click', event, true);
      });

      window.addEventListener('resize', function (event) {
        app.publish('horizontalFacets/resize', event);
      });
    }
  }

  return new FacetHelper();
});
